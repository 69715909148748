.btn-primary {
  color: white;
  background-color: #2C3E50;
  border-color: #2C3E50;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: white;
  background-color: #1a242f;
  border-color: #161f29;
}
.btn-primary.btn-outline {
  color: #428bca;
}